import { styled } from "@hiyllo/ux/styled";
import React from "react";
import { useSelf } from "@hiyllo/omni-continuity";
import * as NymblScheduleBP from "../../../blueprints/calendar/request-ai-schedule-event";
import { seamlessClient } from "../../../seamless-client";
import {
  useEventSummary,
  useNymblTheme,
  useTime,
} from "@hiyllo/omni-dynamicui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faWifiSlash } from "@fortawesome/pro-light-svg-icons";
import { Modal } from "@hiyllo/ux/modal";
import { Input } from "@hiyllo/ux/input";
import { Select } from "@hiyllo/ux/select";
import { AIScheduleUrgencyEnum } from "../../../blueprints/calendar/request-ai-schedule-event";
import { motion } from "framer-motion";
import {
  type InternalInvitee,
  type EventGuestType,
  type CalendarEvent,
} from "../../../types/calendar/calendar-event";
import { Button } from "@hiyllo/ux/button";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { NymblIcon } from "@hiyllo/icons/main";
import { useShowDialog } from "@hiyllo/ux/dialogs";
import { type MoopsyError } from "@moopsyjs/core";
import moment from "moment";
import { useRetrieveEvents } from "../../calendar/hooks/use-retrieve-events";
import { IS_DEV, useShowNymblHome } from "../../../platform/xp";
import { useIsSolo } from "../../../platform/hooks/use-is-solo";
import { GreetingBlock } from "../blocks/greeting-block";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import {
  GuestManagementView,
  createGuestFromUserId,
} from "@hiyllo/omni-calendar";
import { quotes } from "../quotes";
import { MeetingTimer } from "./components/meeting-timer";
import { JoinHiylloMeetButton } from "./web/components/join-hiyllo-meet-button";
import { AssistantChatBlock } from "./components/AssistantChatBlock";
import { Electron } from "../../../platform/electron";
import { TabDetails } from "../../tokyo/tabbing/tabs-provider";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { ContainerSelectorPanel } from "../../stuff/components/container-selector-panel";
import { PillL } from "../../tasks/components/pill";
import { EventDetailsView } from "../../calendar/views/event-details/event-details-view";
import { HideOnBlur, SnowParticles } from "../../../platform/snow";

const HiylloFont = `hiyllo, "hiyllo", hiyllo, "hiyllo"`;
const Easing = [0, 0.45, 0.38, 1];
const CALENDAR_DELAY = 1;

const QuoteContainer = React.memo(function QuoteContainer(props: {
  foreground: string;
}): JSX.Element {
  const randomQuote = React.useMemo(() => {
    return quotes[Math.floor(Math.random() * quotes.length)];
  }, []);

  return (
    <motion.div
      initial={{ translateX: 400 }}
      animate={{ translateX: 0 }}
      transition={{ duration: 1, ease: Easing }}
      style={{
        position: "absolute",
        top: 20,
        right: 20,
        fontFamily: HiylloFont,
        color: props.foreground
      }}
    >
      <div style={{ maxWidth: 300, fontSize: 16 }}>
        <i>{randomQuote.quote}</i>
      </div>
      <div style={{ fontSize: 12, marginTop: 5 }}>{randomQuote.author}</div>
    </motion.div>
  );
});

const Container = styled("div", ({ $theme }) => ({
  backgroundColor: "black",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  backdropFilter: "blur(5spx)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  fontFamily: "hiyllo",
}));

const Centered = styled("div", {
  background: "rgba(0,0,0,0.2)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
});

const CenterTimeBlock = styled("div", ({ $theme }) => ({
  fontFamily: HiylloFont,
  fontSize: 96,
  width: 390,
}));

const FirstMeetingBlockContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: 10,
  backdropFilter: "blur(20px)",
  backgroundColor: "rgba(0,0,0,0.01)",
  paddingTop: 2.5,
  paddingBottom: 2.5,
  overflow: "hidden",
});

const EventsSummaryBlock = React.memo(function EventsSummaryBlock(props: {
  events: CalendarEvent[];
}): JSX.Element {
  const { followingEvents, nextEvent, ttn } = useEventSummary({
    wtlAvailable: false,
  });

  const navigate = useNavigate();
  const onClickJoinMeeting = React.useCallback(() => {
    if (nextEvent != null) {
      navigate({
        feature: Features.meet,
        params: {
          view: "event",
          eventUUID: nextEvent.uuid,
          password: nextEvent.videoMeeting?.meetingPassword ?? "",
        },
      });
    }
  }, [navigate, nextEvent]);
  const onClickJoinNextMeeting = React.useCallback(() => {
    if (followingEvents[0] != null) {
      navigate({
        feature: Features.meet,
        params: {
          view: "event",
          eventUUID: followingEvents[0].uuid,
          password: followingEvents[0].videoMeeting?.meetingPassword ?? "",
        },
      });
    }
  }, [navigate, followingEvents]);

  const nextMeetingSoon = ttn < 30;
  const isSolo = useIsSolo();
  const theme = useNymblTheme();

  const [meetingModal, setMeetingModal] = React.useState<string | null>(null);

  if (nextEvent == null) {
    if (isSolo) {
      return <div>Nothing left on your calendar.</div>;
    } else {
      return <div>No more meetings today.</div>;
    }
  }

  return (
    <div
      style={{
        fontFamily: HiylloFont,
        display: "flex",
        flexDirection: "column",
        gap: 2.5,
        padding: 5,
        overflowY: "hidden",
      }}
    >
      {meetingModal != null ? (
        <Modal onClose={() => setMeetingModal(null)}>
          <EventDetailsView eventUUID={meetingModal} onClose={() => setMeetingModal(null)} />
        </Modal>
      ) : null}
      <FirstMeetingBlockContainer onClick={() => {
        setMeetingModal(nextEvent.uuid);
      }}>
        <div
          style={{ width: 2.5, backgroundColor: theme?.colors.foreground }}
        />
        <div
          style={{
            paddingTop: 2.5,
            paddingBottom: 2.5,
          }}
        >
          {nextMeetingSoon ? (
            <motion.div
              initial={{ fontSize: ttn < 3 ? "60px" : "30px" }}
              animate={{ fontSize: ttn < 3 ? "60px" : "30px" }}
              transition={{ delay: CALENDAR_DELAY }}
            >
              {ttn === 0 ? (
                "Now"
              ) : (
                <MeetingTimer startDate={nextEvent.timing._computed.start} />
              )}
            </motion.div>
          ) : null}
          {nextMeetingSoon ? (
            ttn === 0 ? (
              <div>Meeting has started</div>
            ) : (
              <div>Till your next meeting:</div>
            )
          ) : (
            <div>Your next meeting is:</div>
          )}
          <div style={{ fontSize: 24, fontWeight: "bold" }}>
            {nextEvent.title}
          </div>
          <div style={{ minWidth: 150 }}>
            {nextMeetingSoon ? null : nextEvent.timing._computed.start >
              new Date(Date.now() - 1000 * 60 * 60 * 2) ? (
              <>
                in <MeetingTimer startDate={nextEvent.timing._computed.start} />
              </>
            ) : (
              moment(nextEvent.timing._computed.start).fromNow()
            )}{" "}
            at {moment(nextEvent.timing._computed.start).format("h:mm a")}
          </div>
          <motion.div
            initial={{ height: nextMeetingSoon ? "" : "0px" }}
            animate={{ height: nextMeetingSoon ? "" : "0px" }}
            style={{ overflow: "hidden" }}
          >
            <JoinHiylloMeetButton onClick={evt => {
              evt.stopPropagation();
              onClickJoinMeeting();
            }} />
          </motion.div>
        </div>
      </FirstMeetingBlockContainer>
      {followingEvents.length > 0 ? (
        <>
          <div style={{ height: 5 }} />
          {followingEvents.map((event, i) => {
            if (event.startingSoon) {
              return (
                <motion.div
                  layoutId={event.uuid}
                  key={event.uuid}
                  initial={{ backdropFilter: "blur(0px)" }}
                  animate={{ backdropFilter: "blur(20px)" }}
                  style={{
                    fontSize: 18,
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "rgba(0,0,0,0.01)",
                    paddingTop: 5,
                    paddingBottom: 5,
                  }}
                  transition={{ delay: CALENDAR_DELAY }}
                >
                  <motion.div
                    initial={{ width: 0, marginRight: 0 }}
                    animate={{ width: 2.5, marginRight: 10 }}
                    style={{ backgroundColor: theme?.colors.foreground }}
                    transition={{ delay: CALENDAR_DELAY }}
                  />
                  <div>
                    <div>
                      Up Next in{" "}
                      <MeetingTimer startDate={event.timing._computed.start} />
                    </div>
                    <div style={{ fontSize: 26 }}>
                      <b>{event.title}</b> at{" "}
                      {moment(event.timing._computed.start).format("h:mm a")}
                    </div>
                    <JoinHiylloMeetButton onClick={onClickJoinNextMeeting} />
                  </div>
                </motion.div>
              );
            }

            return (
              <div key={event.uuid} style={{ fontSize: 18 }}>
                {event.gapWithPreviousMessage}
                <b>{event.title}</b> at{" "}
                {moment(event.timing._computed.start).format("h:mm a")}
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
});

const TabIcon = <span style={{ fontSize: 12.5 }}><NymblIcon width={12.5} color="white" /></span>;

export const HomeView = React.memo(function HomeView(): JSX.Element {
  const nymblScheduleMutation =
    seamlessClient.useMutation<NymblScheduleBP.Plug>(NymblScheduleBP);
  const [scheduleMeeting, setScheduleMeeting] = React.useState(false);
  const [smTitle, setSMTitle] = React.useState("");
  const [smDescription, setSMDescription] = React.useState("");
  const [smDuration, setSMDuration] = React.useState(30);
  const [smUrgency, setSMUrgency] = React.useState<AIScheduleUrgencyEnum>(
    AIScheduleUrgencyEnum.asap,
  );
  const self = useSelf();
  const [guests, setGuests] = React.useState<EventGuestType[]>([
    createGuestFromUserId(self.userId),
  ]);
  const navigate = useNavigate();
  const showDialog = useShowDialog();

  const submitScheduleMeeting = React.useCallback(() => {
    void nymblScheduleMutation
      .call({
        title: smTitle,
        description: smDescription,
        duration: smDuration,
        access: {
          users: guests
            .map((g) => g.invitee)
            .map((g) => (g as InternalInvitee).userId)
            .filter((v) => v != null),
          teams: [],
        },
        urgency: smUrgency,
      })
      .then((res) => {
        navigate({
          feature: Features.calendar,
          params: {
            view: "event",
            eventUUID: res.eventUUID,
          },
        });
      })
      .catch((err) => {
        void showDialog({
          title: "Error",
          message: (err as MoopsyError).error,
          noCancel: true,
          confirmLabel: "Continue",
          onConfirm: () => {
            //
          },
        });
      });
  }, [
    guests,
    navigate,
    nymblScheduleMutation,
    showDialog,
    smDescription,
    smDuration,
    smTitle,
    smUrgency,
  ]);

  const time = useTime();

  const reParams = React.useMemo(
    () => ({
      after: new Date(),
      before: moment().endOf("day").toDate(),
      calendarForUsers: [self.userId],
    }),
    [self.userId],
  );

  const eventsQuery = useRetrieveEvents(reParams);
  const { refresh } = eventsQuery;

  React.useEffect(() => {
    void refresh({ subtle: true });
  }, [time.time, refresh]);

  const [bgReady, setBGReady] = React.useState(false);

  const showNymblHome = useShowNymblHome();
  const theme = useNymblTheme(
    new window.URLSearchParams(window.location.search).get("theme") ?? null,
  );
  const isSolo = useIsSolo();

  const ready = bgReady && !eventsQuery.isLoading;

  return (
    <TabDetails
      icon={TabIcon}
      label="Home"
    >
      {scheduleMeeting ? (
        <Modal onClose={() => setScheduleMeeting(false)}>
          <div style={{ minWidth: 400 }}>
            <div style={{ fontFamily: "hiyllo", marginBottom: 5 }}>Title</div>
            <Input
              fullWidth
              value={smTitle}
              onChangeValue={(v) => setSMTitle(v)}
              autoFocus
            />

            <div style={{ height: 20 }} />

            <div style={{ fontFamily: "hiyllo", marginBottom: 5 }}>
              Description
            </div>
            <Input
              fullWidth
              value={smDescription}
              onChangeValue={(v) => setSMDescription(v)}
              autoFocus
              multiline
            />

            <div style={{ height: 20 }} />

            <div style={{ fontFamily: "hiyllo", marginBottom: 5 }}>
              Duration
            </div>
            <Select
              fullWidth
              value={smDuration}
              onChangeValue={(v) => setSMDuration(v as number)}
              options={[
                {
                  value: 15,
                  label: "15 minutes",
                },
                {
                  value: 30,
                  label: "30 minutes",
                },
                {
                  value: 45,
                  label: "45 minutes",
                },
                {
                  value: 60,
                  label: "60 minutes",
                },
                {
                  value: 90,
                  label: "90 minutes",
                },
                {
                  value: 120,
                  label: "120 minutes",
                },
              ]}
            />

            <div style={{ height: 20 }} />

            <div style={{ fontFamily: "hiyllo", marginBottom: 5 }}>Urgency</div>
            <Select
              fullWidth
              value={smUrgency}
              onChangeValue={(v) => setSMUrgency(v as AIScheduleUrgencyEnum)}
              options={[
                {
                  value: AIScheduleUrgencyEnum.asap,
                  label: "ASAP",
                },
                {
                  value: AIScheduleUrgencyEnum.thisWeek,
                  label: "This Week",
                },
                {
                  value: AIScheduleUrgencyEnum.nextWeek,
                  label: "Next Week",
                },
              ]}
            />

            <div style={{ height: 20 }} />
            <GuestManagementView
              onChanged={setGuests}
              defaultGuests={[createGuestFromUserId(self.userId)]}
            />
            <div style={{ height: 20 }} />
            <Button
              label="Schedule Meeting"
              onClick={submitScheduleMeeting}
              isLoading={nymblScheduleMutation.isLoading}
            />
          </div>
        </Modal>
      ) : null}
      <Container>
        <HideOnBlur>
          <SnowParticles zIndex={1} particleCount={500} />
        </HideOnBlur>
        {theme == null ? null : theme.background.type === "video" ? (
          <video
            onPlay={() => setBGReady(true)}
            onLoadedMetadata={() => setBGReady(true)}
            playsInline
            autoPlay
            muted
            loop
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
              opacity: 0.85,
            }}
          >
            {theme.background.srcs.map((source) => (
              <source
                src={source.src}
                type={source.mimeType}
                key={source.mimeType}
              />
            ))}
          </video>
        ) : (
          <img
            onLoad={() => setBGReady(true)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: 0,
              opacity: 0.85,
            }}
            src={theme.background.src}
          />
        )}
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "black",
            fontSize: 24,
            pointerEvents: "none",
            zIndex: 9,
          }}
          initial={{ opacity: 1 }}
          animate={{ opacity: ready ? 0 : 1 }}
          transition={{ duration: 0.5 }}
        >
          {navigator.onLine ? <NymblIcon width={30} color="white" /> :
            <EmptySplash
              icon={faWifiSlash}
              label="You're Offline"
              hint="Your home page will be here when you're connected again!"
            />
          }
        </motion.div>
        {!showNymblHome || ready ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "calc(100% - 40px)",
              padding: 20,
              justifyContent: "flex-start",
              position: "absolute",
              top: 0,
            }}
          >
            <motion.div
              initial={{ translateY: -50 }}
              animate={{ translateY: 0 }}
              transition={{ duration: 1, ease: Easing }}
            >
              {isSolo ? null : (
                <PillL onClick={() => setScheduleMeeting(true)}>
                  <FontAwesomeIcon icon={faCalendar} />
                  Have Nymbl Schedule a Meeting (Beta)
                </PillL>
              )}
            </motion.div>
          </div>
        ) : null}
        {showNymblHome && ready ? (
          <>
            <Centered>
              <div
                style={{
                  maxWidth: 600,
                  textShadow:
                    theme?.colors.foreground === "#ffffff"
                      ? "black 0px 0px 5px, black 0px 0px 8px, black 0px 0px 10px"
                      : "",
                  color: theme?.colors.foreground,
                  background:
                    theme?.colors.foreground === "#ffffff"
                      ? "rgba(0, 0, 0, 0.2)"
                      : "rgba(255, 255, 255, 0.2)",
                  boxShadow:
                    theme?.colors.foreground === "#ffffff"
                      ? "rgba(0, 0, 0, 0.2) 0 0 20px 20px"
                      : "rgba(255, 255, 255, 0.2) 0 0 20px 20px",
                  padding: 10,
                  borderRadius: 20,
                  zIndex: 1,
                }}
                id="home-center-block"
              >
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 1 }}
                >
                  <GreetingBlock color={theme?.colors.foreground ?? ""} />
                  <CenterTimeBlock>
                    {time.time}
                    {time.prefix}
                  </CenterTimeBlock>
                </motion.div>
                <AnimateChangeInHeight
                  transition={{ duration: 1, ease: Easing, delay: 0.5 }}
                  initalHeight={0}
                >
                  {eventsQuery.isError || eventsQuery.isLoading || !ready ? (
                    <div />
                  ) : (
                    <EventsSummaryBlock events={eventsQuery.data.events} />
                  )}
                </AnimateChangeInHeight>
                {IS_DEV ?
                  <AssistantChatBlock color={theme?.colors.foreground ?? ""} />
                  : null}
              </div>
            </Centered>
            <QuoteContainer foreground={theme?.colors.foreground ?? '#000000'} />
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              style={{
                position: "absolute",
                bottom: 20,
                right: 20,
                opacity: 0.5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                }}
              >
                <div
                  onClick={() => {
                    if (Electron.isElectron) {
                      Electron.callAPI(
                        "selectTheme",
                        Electron.electronHost ?? "",
                      );
                    } else {
                      window.location.href =
                        "https://themes.hiyllo.io?rdr=" +
                        encodeURIComponent(window.location.origin);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    userSelect: "none",
                    fontFamily: HiylloFont,
                  }}
                >
                  Change Theme
                </div>
              </div>
            </motion.div>
          </>
        ) : null}
      </Container>
    </TabDetails>
  );
});
