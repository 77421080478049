import React from "react";

import * as GetBookATimeScheduleBP from "../../../blueprints/calendar/book-a-time/get-book-a-time-schedule";
import * as DeleteBookATimeScheduleBP from "../../../blueprints/calendar/book-a-time/delete-book-a-time-schedule";
import { seamlessClient } from "../../../seamless-client";
import { styled } from "@hiyllo/ux/styled";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { useNavigateTo } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { CopyableLinkBox } from "../../../ux/copyable-link-box";
import { LoadingSpinner } from "@hiyllo/ux/loading-spinner";
import { UserImage } from "@hiyllo/omni-images/main";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import {
  type BookATimeRange,
  type BookATimeMemberType,
} from "../../../types/calendar/book-a-time";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { Button } from "@hiyllo/ux/button";

const SpaceBetweenRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const Header = styled("div", { fontSize: 28 });

const PageContainer = styled("div", { padding: 30 });

const MemberListItem = React.memo(function MemberListItem(props: {
  userId: string;
}): JSX.Element {
  const lookup = useLookupByUserId(props.userId);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
      }}
    >
      {!lookup.isReady ? (
        <LoadingSpinner />
      ) : (
        <>
          <UserImage userId={props.userId} width={20} />
          {lookup.data.name}
        </>
      )}
    </div>
  );
});

export const ManageSchedule = React.memo(function ManageSchedule(props: {
  uuid: string;
}): JSX.Element {
  const getScheduleQuery =
    seamlessClient.useQuery<GetBookATimeScheduleBP.Plug>(
      GetBookATimeScheduleBP,
      { uuid: props.uuid },
    );
  const deleteScheduleMutation =
    seamlessClient.useMutation<DeleteBookATimeScheduleBP.Plug>(
      DeleteBookATimeScheduleBP,
    );
  const navigateToHome = useNavigateTo({
    feature: Features.calendar,
    params: { view: "book-a-time" },
  });

  const deleteSchedule = React.useCallback(() => {
    void deleteScheduleMutation
      .call({ scheduleUUID: props.uuid })
      .then(navigateToHome);
  }, [deleteScheduleMutation, navigateToHome, props.uuid]);

  if (getScheduleQuery.isError) {
    return (
      <div>Something went wrong... ({getScheduleQuery.error.description})</div>
    );
  }

  if (getScheduleQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  const { schedule } = getScheduleQuery.data;

  return (
    <PageContainer>
      <SpaceBetweenRow>
        <Header>{schedule.name}</Header>
        <Button
          onClick={deleteSchedule}
          label="Delete"
          isLoading={deleteScheduleMutation.isLoading}
        />
      </SpaceBetweenRow>
      <CopyableLinkBox
        label="Share this link with people to book a time with you"
        link={`${window.location.origin}/b/${schedule.token}`}
      />
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          marginTop: 20,
          marginBottom: 5,
        }}
      >
        Members
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        {schedule.members.map((member: BookATimeMemberType) => (
          <MemberListItem userId={member.userId} key={member.userId} />
        ))}
      </div>
      <div
        style={{
          fontSize: 24,
          fontWeight: "bold",
          marginTop: 20,
          marginBottom: 5,
        }}
      >
        Schedule
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        {schedule.range.map((range: BookATimeRange, i: number) => (
          <div key={i}>
            {range.day},{" "}
            {moment
              .tz(range.from.timezone)
              .hours(range.from.hours)
              .minutes(range.from.minutes)
              .tz(moment.tz.guess())
              .format("h:mm a")}{" "}
            -{" "}
            {moment
              .tz(range.to.timezone)
              .hours(range.to.hours)
              .minutes(range.to.minutes)
              .tz(moment.tz.guess())
              .format("h:mm a")}
          </div>
        ))}
      </div>
      <div style={{ marginTop: 20 }}>
        <FontAwesomeIcon icon={faInfoCircle} /> Editing is not currently
        supported
      </div>
    </PageContainer>
  );
});
