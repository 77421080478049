import React from "react";
import { useTasksInSprint } from "../sprints/hooks/use-tasks-in-sprint";
import { useSprintOrder } from "../hooks/use-sprint-order";
import { styled } from "@hiyllo/ux/styled";
import { useNavigate } from "@hiyllo/omni-router";
import { Features } from "../../../types/navigation/features";
import { useGetSprint } from "../hooks/use-get-sprint";
import { UserImage } from "@hiyllo/omni-images/main";
import { createTaskFilterFunction } from "../hql";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { TasksView } from "./tasks-view";
import { Pill, PillContainer } from "../components/pill";
import { useLookupByUserId } from "@hiyllo/omni-continuity/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/pro-light-svg-icons";

const Container = styled("div", {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

function arrayToggle(arr: any[], value: any): typeof arr {
  const wa = [...arr];
  const index = wa.indexOf(value);

  if (index !== -1) {
    wa.splice(index, 1);
  } else {
    wa.push(value);
  }

  return wa;
}

const UserName = React.memo(function UserName(props: {
  userId: string;
}): string | null {
  const user = useLookupByUserId(props.userId);

  return user.data?.name ?? null;
});

export const SprintKanbanView = React.memo(function SprintKanbanView(props: {
  sprintUUID: string;
}): JSX.Element {
  const tasks = useTasksInSprint(props.sprintUUID);
  const { order, onChangeOrder } = useSprintOrder({
    sprintUUID: props.sprintUUID,
  });
  const navigate = useNavigate();
  const sprintQuery = useGetSprint({ sprintUUID: props.sprintUUID });
  const openSprintPlanning = React.useCallback(() => {
    if (sprintQuery.isError || sprintQuery.isLoading) return;

    navigate({
      feature: Features.tasks,
      params: {
        view: "sprint-planning",
        sprintUUID: props.sprintUUID,
        teamUUID: sprintQuery.data.sprint.teamUUID,
      },
    });
  }, [navigate, props.sprintUUID, sprintQuery]);
  const [filters, setFilters] = React.useState<string[]>([]);

  const filteredTasks = React.useMemo(() => {
    const filter = filters.join(" AND ");
    return tasks.data.filter(createTaskFilterFunction(filter));
  }, [filters, tasks.data]);
  const assignees: string[] = React.useMemo(() => {
    const rv: string[] = [];
    filteredTasks
      .map((task) => task.assigneeUserId)
      .forEach((p) => {
        if (p != null && !rv.find((r) => r === p)) {
          rv.push(p);
        }
      });
    return rv;
  }, [filteredTasks]);

  if (order === null) {
    return <LoadingSpinnerFullView />;
  }

  return (
    <Container>
      <TasksView
        order={order}
        onChangeOrder={onChangeOrder}
        tasks={filteredTasks}
        isReady={!tasks.queryLoading}
        hideTaskProjectLabels={false}
        extraHeaderOptions={
          assignees.length === 0 ? <></> :
            <PillContainer>
              <FontAwesomeIcon icon={faFilter} />
              {assignees.map((assignee) => (
                <Pill
                  key={assignee}
                  inactive={!filters.includes(`assignee IS ${assignee}`)}
                  onClick={() =>
                    setFilters((f) =>
                      arrayToggle(f, `assignee IS ${assignee}`),
                    )
                  }
                >
                  <UserImage userId={assignee} width={17.5} />
                  <UserName userId={assignee} />
                </Pill>
              ))}
            </PillContainer>
        }
      />
    </Container>
  );
});
