import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

interface SnowParticlesProps {
  zIndex?: number;
  particleCount?: number;
}

interface Particle {
  id: number;
  size: number;
  left: string;
  animationDuration: number;
  delay: number;
}

export const HideOnBlur = React.memo(function HideOnBlur(props: React.PropsWithChildren) {
  const [blurred, setBlurred] = useState(false);

  React.useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hasFocus()) {
        setBlurred(true);
      } else {
        setBlurred(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  if (blurred) {
    return null;
  }

  return <>{props.children}</>;
});

export const SnowParticles = React.memo(function SnowParticles({
  zIndex = 10,
  particleCount = 100,
}: { zIndex?: number, particleCount?: number }): JSX.Element {
  const [particles, setParticles] = useState<Particle[]>([]);

  useEffect(() => {
    // Generate an array of random snow particles
    const generateParticles = (): Particle[] => {
      return Array.from({ length: particleCount }, (_, i) => ({
        id: i,
        size: Math.random() * 5 + 2, // Random size between 2px and 7px
        left: Math.random() * 100 + "%", // Random position across the screen width
        animationDuration: Math.random() * 15 + 5, // Random duration between 2s and 5s
        delay: Math.random() * 5, // Random delay between 0s and 5s
      }));
    };

    setParticles(generateParticles());
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        pointerEvents: "none",
        overflow: "hidden",
        zIndex,
      }}
    >
      {particles.map((particle) => (
        <motion.div
          key={particle.id}
          style={{
            position: "absolute",
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            backgroundColor: "white",
            borderRadius: "50%",
            opacity: 0.8,
            left: particle.left,
          }}
          animate={{
            y: ["-10vh", "110vh"], // Falling down from above the screen to below
            x: [`-${Math.random() * 10}vw`, `${Math.random() * 100}vw`], // Random x position
          }}
          transition={{
            duration: particle.animationDuration,
            delay: particle.delay,
            repeat: Infinity,
            ease: "linear",
          }}
        />
      ))}
    </div>
  );
});