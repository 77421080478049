import { useSlate, ReactEditor } from 'slate-react';
import { positions, Range } from 'slate';
import React from 'react';
import ReactDOM from 'react-dom';
import { KeyCombinationHint } from '../editor-v2';
import { Typography } from '@hiyllo/ux/typography';
import { motion } from 'framer-motion';
import { Command, CommandPalette } from './command-palette';

export const Caret = React.memo(function CustomCaret(props: {
  altKeyHeld: boolean;
  commandPaletteOpen: boolean;
  editor: ReactEditor;
  onCloseCommandPalette: () => void;
  customCommands?: Command[];
}) {
  const editor = useSlate();
  const [caretRect, setCaretRect] = React.useState<{ rect: DOMRect, collapsed: boolean } | null>(null);

  const updateCaretPosition = React.useCallback(() => {
    window.requestAnimationFrame(() => {
      const { selection } = editor;
      if (selection) {
        const domSelection = window.getSelection();
        if (domSelection == null) {
          return setCaretRect(null);
        }
        const domRange = domSelection.getRangeAt(0).cloneRange();
        const rect = domRange.getBoundingClientRect();
        setCaretRect({ rect, collapsed: Range.isCollapsed(selection) });
      } else {
        setCaretRect(null);
      }
    });
  }, [editor]);

  // Update caret position when selection changes
  React.useEffect(() => {
    // Listen for selection changes
    const { onChange } = editor;
    editor.onChange = () => {
      onChange();
      updateCaretPosition();
    };

    // Initial update
    updateCaretPosition();

    // Clean up on unmount
    return () => {
      editor.onChange = onChange;
    };
  }, [editor, updateCaretPosition]);

  React.useEffect(() => {
    const handleWindowEvent = () => {
      // Recalculate caret position
      updateCaretPosition();
    };

    window.addEventListener('scroll', handleWindowEvent);
    window.addEventListener('resize', handleWindowEvent);

    return () => {
      window.removeEventListener('scroll', handleWindowEvent);
      window.removeEventListener('resize', handleWindowEvent);
    };
  }, [updateCaretPosition]);

  const top = (caretRect?.rect?.top ?? 0) + window.scrollY + (caretRect?.rect?.height ?? 0) + 5;
  const left = (caretRect?.rect?.left ?? 0) + window.scrollX;

  const style = React.useMemo(() => {
    const s = {};
    if (top > window.innerHeight - 400) {
      Object.assign(s, { position: "absolute", bottom: "1em" });
    }
    if (left > window.innerWidth - 400) {
      Object.assign(s, { position: "absolute", right: "1em" });
    }
    return s;
  }, [left, top]);

  if (!caretRect) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'absolute',
        zIndex: 1,
        top,
        left,
        width: props.commandPaletteOpen ? undefined : 0, // Customize width
        height: caretRect.rect.height,
        pointerEvents: 'none', // Allow clicks to pass through
      }}
    >
      <div style={{ position: "relative" }}>
        <div style={style}>


          {props.commandPaletteOpen ?
            <CommandPalette
              editor={props.editor}
              onClose={props.onCloseCommandPalette}
              customCommands={props.customCommands}
            />
            :
            <motion.div
              animate={{
                opacity: props.altKeyHeld ? 1 : 0,
              }}
              initial={{ opacity: 0 }}
              transition={{
                duration: 0.1,
                ease: [0.1, 0.12, 0.1, 0.95]
              }}
            >
              <div style={{ backdropFilter: "blur(2.5px)", backgroundColor: "rgba(0,0,0,0)", width: "fit-content", padding: 5, borderRadius: 5 }}>
                <div style={{ display: "flex", flexDirection: "column", fontSize: 10, flexWrap: "nowrap", whiteSpace: "nowrap", gap: 2.5, color: "white" }}>
                  <Typography.HeaderRow>
                    <KeyCombinationHint combination={["alt", "Enter"]} /> Command Palette
                  </Typography.HeaderRow>
                  {caretRect.collapsed ? <>
                    <Typography.HeaderRow>
                      <KeyCombinationHint combination={["alt", "Shift", "W"]} /> Select Nearest Word
                    </Typography.HeaderRow>
                    <Typography.HeaderRow>
                      <KeyCombinationHint combination={["alt", "Shift", "L"]} /> Select Line
                    </Typography.HeaderRow>
                  </> : null}
                </div>
              </div>
            </motion.div>
          }
        </div>
      </div>
    </div>,
    document.body
  );
});