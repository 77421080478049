import _ from "lodash";
import React from "react";
import { motion } from "framer-motion";
import { styled } from "@hiyllo/ux/styled";
import { usePath } from "@hiyllo/omni-router";
import { useAlertsContext } from "@hiyllo/omni-alerts";

import { type EDataType } from "../types/navigation/edata";
import { AlertFeature, AlertSpecialType, type AlertType } from "../types/alerts/alert";
import { AlertCard } from "../features/tokyo/components/alerts/alert-item";
import { AlertCallCard } from "../features/tokyo/components/alerts/alert-call-card";
import { AlertEventCard } from "../features/tokyo/components/alerts/alert-event-card";
import { AlertMessageCard } from "../features/tokyo/components/alerts/alert-message-card";
import { Features } from "../types/navigation/features";

const AlertPopupContainer = styled("div", ({ $theme }) => ({
  borderRadius: 5,
  overflow: "hidden",
  background: $theme.background3,
  color: $theme.foreground,
  boxShadow: "rgba(0,0,0,0.20) 0px 0px 15px 15px",
  maxWidth: 300,
  zIndex: 1001
}));

export const AlertsInAppProvider = React.memo(function AlertsInAppProvider(): JSX.Element {
  const path = usePath();
  const pathRef = React.useRef<EDataType>(path);
  React.useEffect(() => {
    pathRef.current = path;
  }, [path]);
  const { useOnAlertReceived, useOnAlertDeleted, alertsCountQuery } =
    useAlertsContext();
  const [displayAlerts, setDisplayAlerts] = React.useState<AlertType[]>(
    [],
  );

  const pluckAlertByUUID = React.useCallback(
    (alertUUID: string) => {
      setDisplayAlerts((alerts) =>
        alerts.filter((a) => (a !== null && a.uuid === alertUUID ? null : a)),
      );
    },
    [],
  );

  const pluckDuplicateMeetingAlerts = React.useCallback(
    (eventUUID: string) => {
      setDisplayAlerts((alerts) =>
        alerts.filter((a) => {
          if (a.ead?.type === "meeting" && a.ead.eventUUID === eventUUID && a.feature === AlertFeature.calendar) {
            return null;
          }
          return a;
        }),
      );
    },
    [],
  );

  const pushAlert = React.useCallback(
    (alert: AlertType) => {
      setDisplayAlerts((alerts) => {
        if (alerts.some((a) => a.uuid === alert.uuid)) {
          return alerts;
        }
        return [...alerts, alert];
      });
    },
    [],
  );

  useOnAlertDeleted(async (alertUUID) => {
    pluckAlertByUUID(alertUUID);
  });

  useOnAlertReceived(async (_alert) => {
    const alert = _alert as AlertType;
    const currentPath = pathRef.current;

    if (alert?.link != null && "enav" in alert.link) {
      if (
        currentPath.feature === alert.link.enav.feature &&
        _.isEqual(currentPath.params, alert.link.enav.params)
      ) {
        return;
      }
    }

    if (alert != null && window.localStorage.muteAlerts !== "1") {
      pushAlert(alert);

      if (alert.ead?.type === "meeting" && alert.ead.eventUUID != null) {
        pluckDuplicateMeetingAlerts(alert.ead.eventUUID);
      }

      setTimeout(
        () => {
          pluckAlertByUUID(alert.uuid);
        },
        alert?.persistVisibleTill != null
          ? alert.persistVisibleTill.valueOf() - Date.now()
          : 10000,
      );
    }
  });

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: 20,
          left: 20,
          zIndex: 1000,
          pointerEvents: displayAlerts.length === 0 ? "none" : "auto",
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        {displayAlerts.map((displayAlert) => (
          <motion.div
            key={displayAlert.uuid}
            initial={{
              translateX: "-100%",
            }}
            animate={{
              translateX: "0%",
            }}
            exit={{
              translateX: "-100%",
            }}
          >
            <AlertPopupContainer
              style={
                displayAlert.ead?.type === "meeting"
                  ? { background: "transparent" }
                  : {}
              }
            >
              {displayAlert.ead?.type === "chat/message" ?
                <AlertMessageCard ead={displayAlert.ead} onClose={() => pluckAlertByUUID(displayAlert.uuid)} />
                : displayAlert.ead?.type === "meeting" ? (
                  <AlertEventCard ead={displayAlert.ead} onClose={() => pluckAlertByUUID(displayAlert.uuid)} />
                ) : displayAlert.specialType === AlertSpecialType.call ? (
                  <AlertCallCard
                    alert={displayAlert}
                    onClose={() => {
                      pluckAlertByUUID(displayAlert.uuid);
                    }}
                  />
                ) : (
                  <AlertCard
                    isPopup={true}
                    key={displayAlert.uuid}
                    alert={displayAlert}
                    alertsCountQuery={alertsCountQuery}
                    onClose={() => {
                      pluckAlertByUUID(displayAlert.uuid);
                    }}
                  />
                )}
            </AlertPopupContainer>
          </motion.div>
        ))}
      </div>
    </>
  );
});
