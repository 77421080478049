import React from "react";

import * as GetThreadBP from "../../../blueprints/mail/get-thread";
import * as SetEmailReadBP from "../../../blueprints/mail/set-email-read";
import * as DiscardDraftBP from "../../../blueprints/mail/discard-draft";
import * as MarkSpamBP from "../../../blueprints/mail/mark-email-spam";
import { seamlessClient } from "../../../seamless-client";
import { LoadingSpinnerFullView } from "../../../platform/loading/spinner-loading-full";
import { styled } from "@hiyllo/ux/styled";
import {
  MailMessageStatusEnum,
  type MailMessageAttachmentEnhanced,
  type MailMessageEnhanced,
} from "../../../types/mail/message/message";
import {
  faAngleDown,
  faBadgeCheck,
  faCircleExclamation,
  faExclamationTriangle,
  faFile,
  faShare,
  faHexagonExclamation,
  faReply,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { UserImage } from "@hiyllo/omni-images/main";
import moment from "moment";
import { ComposeView } from "./compose-view";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";
import { useNavigate, useNavigateTo, useSelf } from "@hiyllo/omni-continuity";
import { useConfig } from "../../../platform/config/config-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { RenderAlreadySafe, RenderSafe } from "./render-safe";
import { Button } from "@hiyllo/ux/button";
import { useAlertDialog, useShowConfirmationDialog, useShowDialog } from "@hiyllo/ux/dialogs";
import { MoopsyError } from "@moopsyjs/core";
import { UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { useTheme } from "@hiyllo/ux/theme";
import { Features } from "../../../types/navigation/features";
import { BuiltInFolderEnum } from "../../../types/mail/organization/builtin-folders";
import { Centered } from "../../../ux/alpha";
import { CircleButton } from "@hiyllo/ux/circle-button";
import { EmptySplash } from "@hiyllo/ux/empty-splash";
import { FileGallery } from "../../../platform/file-gallery";
import { Modal } from "@hiyllo/ux/modal";

const Container = styled("div", {
  height: "calc(100% - 20px)",
  padding: 10,
  display: "flex",
  flexDirection: "column",
  gap: 8,
  overflowY: "auto",
});

const MessageContainer = styled("div", ({ $theme }) => ({
  background: $theme.background3,
  color: $theme.foreground,
  padding: 15,
  borderRadius: 10,
}));

const MessageContentContainer = styled("div", {
  // whiteSpace: "pre-wrap",
  overflowX: "hidden",
});

export function fillBetween<T>(arr: T[], filler: T): T[] {
  const out = [];
  for (let i = 0; i < arr.length - 1; i++) {
    out.push(arr[i]);
    out.push(filler);
  }
  out.push(arr[arr.length - 1]);
  return out;
}


const MessageHeaderContainer = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
  marginBottom: 8,
}));

const WarningCard = styled("div", ({ $theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  // background: "#ff9800",
  color: "#ff9800",
  border: "2px #ff9800 solid",
  padding: 10,
  borderRadius: 10,
}));

const AngleIcon = styled<"div">(motion.div, ({ $theme }) => ({
  background: $theme.midground,
  height: 24,
  width: 24,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
})) as typeof motion.div;

const ExpandedHeader = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  height: 1,
  width: "100%",
}));

const AttachmentContainer = styled("div", ({ $theme }) => ({
  background: $theme.midground,
  padding: 10,
  borderRadius: 10,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  cursor: "pointer",
}));

const Attachment = React.memo(function Attachment(props: {
  attachment: MailMessageAttachmentEnhanced;
}): JSX.Element {
  const [showPreview, setShowPreview] = React.useState(false);

  return (
    <>
      {showPreview ?
        <Modal onClose={() => setShowPreview(false)}>
          <FileGallery
            files={[{ src: props.attachment.src }]}
            showDownload
          />
        </Modal>
        : null}
      <AttachmentContainer onClick={() => setShowPreview(true)}>
        <div>
          <FontAwesomeIcon icon={faFile} fixedWidth />
        </div>
        <div>
          <div>{props.attachment.filename}</div>
          <div style={{ fontSize: 10, marginTop: 2.5 }}>
            {props.attachment.size > 1000 * 1000
              ? `${(props.attachment.size / 1000 / 1000).toFixed(2)}mb`
              : `${(props.attachment.size / 1000).toFixed(2)}kb`}
          </div>
        </div>
      </AttachmentContainer>
    </>
  );
});

const Message = React.memo(function Message(props: {
  message: MailMessageEnhanced;
  threadQuery: UseMoopsyQueryRetValAny;
  hasNonDraftMessages: boolean;
  setInReplyTo: (inReplyTo: string | null) => void;
  setForward: (forward: string | null) => void;
}): JSX.Element {
  const $theme = useTheme();
  const [expandedHeader, setExpandedHeader] = React.useState(false);
  const [dateClicks, setDateClicks] = React.useState(0);
  const onClickDate = React.useCallback(() => {
    setDateClicks((v) => v + 1);
  }, []);
  const showAlert = useAlertDialog();
  const discardDraftMutation = seamlessClient.useMutation<DiscardDraftBP.Plug>(DiscardDraftBP, {
    querySideEffects: [props.threadQuery]
  });
  const markSpamMutation = seamlessClient.useMutation<MarkSpamBP.Plug>(MarkSpamBP);
  const returnToList = useNavigateTo({
    feature: Features.mail,
    params: {
      view: 'mail',
      folder: BuiltInFolderEnum.inbox
    }
  });
  const deleteDraft = React.useCallback(() => {
    void discardDraftMutation.call({
      messageUUID: props.message.uuid
    }).then(() => {
      if (!props.hasNonDraftMessages) {
        returnToList();
      }
    }).catch((err) => {
      void showAlert({
        title: "Error discarding draft",
        message: (err as MoopsyError).description
      });
    });
  }, [discardDraftMutation, props.hasNonDraftMessages, props.message.uuid, returnToList, showAlert]);
  const showConfirm = useShowConfirmationDialog();
  const navigate = useNavigate();

  const markSpam = React.useCallback(() => {
    void showConfirm({
      title: "Mark as Spam",
      message: "Are you sure you want to mark this message as spam? Hiyllo will be sent this email to improve our detection algorithms",
    }).then(res => {
      if (res) {
        void markSpamMutation.call({
          uuid: props.message.uuid
        }).then(() => {
          navigate({
            feature: Features.mail,
            params: {
              view: "mail",
              folder: BuiltInFolderEnum.inbox,
            },
          });
        }).catch((err) => {
          void showAlert({
            title: "Error marking as spam",
            message: (err as MoopsyError).description
          });
        });
      }
    });
  }, []);

  if (props.message.status === MailMessageStatusEnum.draft) {
    return (
      <div style={{
        padding: 5,
        borderColor: $theme.midground,
        borderStyle: "solid",
        borderWidth: 1,
        borderRadius: 10
      }}>
        <div style={{ paddingLeft: 10, paddingRight: 10, display: "flex", flexDirection: 'row', justifyContent: "space-between", alignItems: "center" }}>
          <div>Draft</div>
          <Button
            isSecondary
            label="Discard Draft"
            onClick={deleteDraft}
            isLoading={discardDraftMutation.isLoading}
          />
        </div>
        <div style={{ height: 500 }}>
          <ComposeView
            inReplyTo={props.message.base.inReplyTo}
            to={props.message.base.to}
            cc={props.message.base.cc}
            bcc={props.message.base.bcc}
            subject={props.message.base.subject}
            draftMessageUUID={props.message.uuid}
            html={props.message.base.html}
          />
        </div>
      </div>
    );
  }

  return (
    <MessageContainer>
      <MessageHeaderContainer>
        <UserImage userId="1" width={32} />
        <div>
          <div>{props.message.base.from.name}</div>
          <div
            style={{
              fontSize: 12,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {props.message.base.from.address}

            <FontAwesomeIcon
              icon={
                props.message.isVerified
                  ? faBadgeCheck
                  : faTriangleExclamation
              }
              style={{ marginLeft: 4 }}
            />
          </div>
        </div>
        <AngleIcon
          onClick={() => setExpandedHeader((v) => !v)}
          animate={{ rotate: expandedHeader ? 180 : 0 }}
        >
          <FontAwesomeIcon icon={faAngleDown} />
        </AngleIcon>
        <div style={{ flexGrow: 1, width: 16 }} />
        <div onClick={onClickDate}>
          <div style={{ fontSize: 12 }}>
            {moment(props.message.date).format("h:mm a, dddd MMM Do, YYYY")}
          </div>
        </div>
        <CircleButton icon={faReply} size={25} onClick={() => props.setInReplyTo(props.message.uuid)} />
        <CircleButton icon={faShare} size={25} onClick={() => props.setForward(props.message.uuid)} />
        <CircleButton icon={faCircleExclamation} size={25} onClick={markSpam} secondary />
      </MessageHeaderContainer>
      <AnimateChangeInHeight>
        {expandedHeader ? (
          <div style={{ fontSize: 12 }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <div>
                <b style={{ userSelect: "none" }}>To: </b>
                {fillBetween(props.message.base.to.map(v => <>{v}</>), <span style={{ userSelect: "none" }}>, </span>)}
              </div>
              {props.message.base.cc.length > 0 ? (
                <div>
                  <b style={{ userSelect: "none" }}>Cc: </b>
                  {fillBetween(props.message.base.cc.map(v => <>{v}</>), <span style={{ userSelect: "none" }}>, </span>)}
                </div>
              ) : null}
              {props.message.base.bcc.length > 0 ? (
                <div>
                  <b style={{ userSelect: "none" }}>Bcc: </b>
                  {fillBetween(props.message.base.bcc.map(v => <>{v}</>), <span style={{ userSelect: "none" }}>, </span>)}
                </div>
              ) : null}
              <div>
                <b style={{ userSelect: "none" }}>From: </b>
                {props.message.base.from.address}
              </div>
              <div>
                <b style={{ userSelect: "none" }}>Subject: </b>
                {props.message.base.subject}
              </div>
              <div>
                <b style={{ userSelect: "none" }}>Signed By: </b>
                {props.message.base.signedBy ??
                  "Unsigned (Proceed with caution)"}
              </div>
            </div>
            <div style={{ height: 8 }} />
            <ExpandedHeader />
            <div style={{ height: 8 }} />
          </div>
        ) : null}
      </AnimateChangeInHeight>
      {props.message.flaggedAsSpam === true ? (
        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
          <WarningCard>
            <FontAwesomeIcon icon={faHexagonExclamation} />
            <div>This message was flagged as spam. Proceed with caution.</div>
          </WarningCard>
        </div>
      ) : null}
      <MessageContentContainer>
        {dateClicks > 20 ? (
          <div
            style={{
              backgroundColor: "black",
              whiteSpace: "pre-wrap",
              fontFamily: "monospace",
              color: "white",
              padding: 10,
              borderRadius: 10,
              wordWrap: "break-word",
            }}
          >
            {props.message.renderableHTML}
            <hr />
            {props.message.base.html}
            <hr />
            <div><b>{props.message.base.subject}</b></div>
            <div>{props.message.base.text}</div>
            <hr />
            {props.message.debugData}
            <hr />
            {JSON.stringify(props.message.base.headerLines, null, 2)}
            <hr />
            {JSON.stringify(props.message.base.headers, null, 2)}
            <hr />
            {JSON.stringify(props.message.base.rawAuth, null, 2)}
          </div>
        ) : props.message.renderableHTML != null ? (
          <RenderAlreadySafe content={props.message.renderableHTML} />
        ) : (
          <RenderSafe content={props.message.base.html} />
        )}
      </MessageContentContainer>
      {props.message.enhancedAttachments.length > 0 ? (
        <MessageAttachmentsRow>
          {props.message.enhancedAttachments.map((attachment) => (
            <Attachment key={attachment.fsId} attachment={attachment} />
          ))}
        </MessageAttachmentsRow>
      ) : null}
    </MessageContainer>
  );
});

const MessageAttachmentsRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginTop: 10,
  gap: 10,
  overflowX: "auto"
});

export const MailThreadView = React.memo(function MailThreadView(props: {
  threadUUID: string;
}): JSX.Element {
  const [inReplyTo, setInReplyTo] = React.useState<string | null>(null);
  const [forward, setForward] = React.useState<string | null>(null);
  const threadQuery = seamlessClient.useQuery<GetThreadBP.Plug>(
    GetThreadBP,
    { threadUUID: props.threadUUID },
  );
  const setEmailReadMutation =
    seamlessClient.useMutation<SetEmailReadBP.Plug>(SetEmailReadBP);
  const self = useSelf();
  const config = useConfig();

  React.useEffect(() => {
    if (threadQuery.data != null) {
      const unreadMessages = threadQuery.data.messages.filter((m) => !m.read);
      for (const urm of unreadMessages) {
        void setEmailReadMutation.call({
          uuid: urm.uuid,
          read: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadQuery.data]);

  if (threadQuery.isError) {
    return (
      <EmptySplash
        icon={faExclamationTriangle}
        label="Error loading thread"
        hint={(threadQuery.error as MoopsyError).message}
      />
    );
  }

  if (threadQuery.isLoading) {
    return <LoadingSpinnerFullView />;
  }

  const firstMessage = threadQuery.data.messages.at(-1) as MailMessageEnhanced;
  const lastMessage = threadQuery.data.messages[0];
  const sender = `${self.username ?? ""}@${config.emailDomain ?? ""}`;
  const hasNonDraftMessages = threadQuery.data.messages.some(m => m.status !== MailMessageStatusEnum.draft);
  const inReplyToMessage: MailMessageEnhanced | null = threadQuery.data.messages.find(m => m.uuid === inReplyTo) ?? null;
  const forwardMessage: MailMessageEnhanced | null = threadQuery.data.messages.find(m => m.uuid === forward) ?? null;

  if (firstMessage == null || lastMessage == null) {
    return (
      <Centered>
        No Messages
      </Centered>
    );
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 10,
          paddingTop: 10,
        }}
      >
        <div style={{ fontSize: 24, padding: 0 }}>
          {threadQuery.data.messages[0].base.subject}
        </div>
      </div>
      <div style={{ flexShrink: 0 }}>
        <AnimateChangeInHeight>
          {inReplyToMessage != null ? (
            <div
              style={{
                height: 600,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ComposeView
                noPadding
                inReplyTo={inReplyToMessage.uuid}
                to={[
                  ...new Set([
                    inReplyToMessage.base.from.address,
                    ...inReplyToMessage.base.to,
                  ]),
                ].filter((a) => a !== sender)}
                cc={inReplyToMessage.base.cc}
                bcc={inReplyToMessage.base.bcc}
                subject={`Re: ${firstMessage.base.subject}`}
              />
            </div>
          ) : null}
        </AnimateChangeInHeight>
        <AnimateChangeInHeight>
          {forwardMessage != null ? (
            <div
              style={{
                height: 600,
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ComposeView
                noPadding
                inReplyTo={forwardMessage.uuid}
                subject={`Fwd: ${forwardMessage.base.subject}`}
                forward={forwardMessage}
              />
            </div>
          ) : null}
        </AnimateChangeInHeight>
      </div>
      {threadQuery.data.messages.map((message) => (
        <Message key={message.uuid} message={message} threadQuery={threadQuery} hasNonDraftMessages={hasNonDraftMessages} setInReplyTo={setInReplyTo} setForward={setForward} />
      ))}
    </Container>
  );
});
