import React from "react";
import { type TasksEDataParamsType } from "@hiyllo/omni-common/src/types/navigation/edata";
import { LeftSidebar } from "../../components/left-sidebar";
import { MainContent } from "../../components/main-content";
import { Sidebar } from "@hiyllo/ux/tokyo";
import { CircleButton } from "@hiyllo/ux/circle-button";
import {
  faPlus,
  faSquareKanban,
  faSquareCheck,
  faBriefcase,
  faRunning,
  faRoute,
  faListCheck,
  faCogs,
  faMapPin,
  faDiagramProject,
  faEmptySet,
} from "@fortawesome/pro-light-svg-icons";
import {
  ContextMenuContainer,
  ContextMenuItem,
  useWrappedPopOver,
} from "@hiyllo/ux/context-menu";
import { LSButton } from "../../components/ls-button";
import { useNavigate, useNavigateTo, usePath } from "@hiyllo/omni-router";
import { Features } from "@hiyllo/omni-common/src/types/navigation/features";
import { type TaskProject } from "@hiyllo/omni-common/src/types/tasks/tasks-organization";
import { CreateNewProjectView } from "../../../tasks/views/create-new-project";
import { TaskProjectView } from "../../../tasks/views/project-kanban-view";
import { useMyProjects } from "../../../tasks/hooks/use-my-projects";
import { CreateNewTaskView } from "../../../tasks/views/create-new-task";
import { TaskView } from "../../../tasks/views/task-view";
import { TasksICView } from "../../../tasks/views/tasks-ic-view";
import { ProjectPlanningViewWrapper } from "../../../tasks/project-planning/project-planning-view";
import { SprintPlanningView } from "../../../tasks/sprints/sprint-planning/sprint-planning-view";
import { CreateSprintWrapper } from "../../../tasks/sprints/sprint-planning/create-sprint-view";
import { SprintKanbanView } from "../../../tasks/views/sprint-kanban-view";
import { LSText } from "../../components/ls-text";
import { useListMyTeams } from "../../../organization/hooks/use-list-my-teams";
import { type UseMoopsyQueryRetValAny } from "@moopsyjs/react";
import { SprintStatusEnum } from "@hiyllo/omni-common/src/types/tasks/sprint";
import { useGetPersonalProject } from "../../../tasks/hooks/use-get-personal-project";
import { PersonalProjectSplash } from "../../../tasks/views/personal-project-splash";
import { styled } from "@hiyllo/ux/styled";
import { ProjectSettingsView } from "../../../tasks/views/project-settings-view";
import { useIsSolo } from "../../../../platform/hooks/use-is-solo";
import EventEmitter from "events";
import { UserTasksView } from "../../../tasks/views/user-tasks-view";
import { LSTinyButton } from "../../components/ls-tiny-button";
import { useGetMySprints } from "@hiyllo/omni-tasks";
import { TabDetails } from "../../tabbing/tabs-provider";
import { SprintsContext } from "./contexts";
import { NewSprintButton } from "./new-sprint-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimateChangeInHeight } from "@hiyllo/ux/animation";

const TaskPlusMenu = React.memo(function TaskPlusMenu(): JSX.Element {
  const params = usePath().params as TasksEDataParamsType;

  const createNewTask = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "create-task",
      projectUUID:
        params != null && "projectUUID" in params ? params.projectUUID : null,
      sprintUUID:
        params != null && "sprintUUID" in params ? params.sprintUUID : null,
    },
  });

  const createNewProject = useNavigateTo({
    feature: Features.tasks,
    params: { view: "create-new-project" },
  });

  return (
    <ContextMenuContainer>
      <ContextMenuItem
        icon={faSquareCheck}
        label="New Task"
        onClick={createNewTask}
      />
      <ContextMenuItem
        icon={faSquareKanban}
        label="New Project"
        onClick={createNewProject}
      />
    </ContextMenuContainer>
  );
});

const PinnedProjectsEmitter = new EventEmitter();
export function usePinnedProjects(): {
  pinnedProjects: string[];
  togglePinnedProject: (projectUUID: string) => void;
} {
  const [pinnedProjects, setPinnedProjects] = React.useState<string[]>(() => {
    const pinnedProjects = localStorage.getItem("pinnedProjects");
    return pinnedProjects != null ? JSON.parse(pinnedProjects) : [];
  });

  React.useEffect(() => {
    const listener = (): void => {
      setPinnedProjects(
        JSON.parse(localStorage.getItem("pinnedProjects") ?? "[]"),
      );
    };
    PinnedProjectsEmitter.on("change", listener);
    return () => {
      PinnedProjectsEmitter.off("change", listener);
    };
  }, []);

  const togglePinnedProject = React.useCallback((projectUUID: string) => {
    setPinnedProjects((pinnedProjects) => {
      const v = pinnedProjects.includes(projectUUID)
        ? pinnedProjects.filter((p) => p !== projectUUID)
        : [...pinnedProjects, projectUUID];

      localStorage.setItem("pinnedProjects", JSON.stringify(v));
      PinnedProjectsEmitter.emit("change", v);

      return v;
    });
  }, []);

  return {
    pinnedProjects,
    togglePinnedProject,
  };
}

const TaskProjectLSButton = React.memo(function TaskProjectLSButton(props: {
  projectsQuery: UseMoopsyQueryRetValAny;
  project: TaskProject;
  isActive: boolean;
}): JSX.Element {
  const isSolo = useIsSolo();
  const params = usePath().params as TasksEDataParamsType;
  const onClick = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "project",
      projectUUID: props.project.uuid,
    },
  });
  const onClickProjectPlanning = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "project-planning",
      projectUUID: props.project.uuid,
    },
  });
  const onClickProjectSettings = useNavigateTo({
    feature: Features.tasks,
    params: {
      view: "project-settings",
      projectUUID: props.project.uuid,
    },
  });
  const { open, CXMenuContainer, ref } = useWrappedPopOver<HTMLDivElement>({
    offset: {
      y: -5,
      x: -5,
    },
  });
  const { pinnedProjects, togglePinnedProject } = usePinnedProjects();

  return (
    <>
      <CXMenuContainer>
        <ContextMenuContainer>
          <ContextMenuItem
            icon={faMapPin}
            label={
              pinnedProjects.includes(props.project.uuid) ? "Unpin" : "Pin"
            }
            onClick={() => togglePinnedProject(props.project.uuid)}
          />
        </ContextMenuContainer>
      </CXMenuContainer>
      <LSButton
        key={props.project.uuid}
        label={props.project.name}
        icon={{
          fa: pinnedProjects.includes(props.project.uuid)
            ? faMapPin
            : faDiagramProject,
        }}
        onClick={onClick}
        isActive={props.isActive}
        onContextMenu={open}
        contextButtonRef={ref}
      />
      <AnimateChangeInHeight>
        {props.isActive ? (
          <>
            {!isSolo ? (
              <LSButton
                label="Kanban"
                icon={{ fa: faSquareKanban }}
                onClick={onClick}
                isActive={params?.view === "project"}
                indent
                hasNextSibling
              />
            ) : null}
            <LSButton
              label="Settings"
              icon={{ fa: faCogs }}
              onClick={onClickProjectSettings}
              isActive={params?.view === "project-settings"}
              indent
            />
          </>
        ) : <div />}
      </AnimateChangeInHeight>
    </>
  );
});

const EmptyStateText = styled("div", ({
  fontFamily: '"hiyllo", sans-serif',
  paddingLeft: 20,
  paddingRight: 30,
  color: "#656570",
  marginTop: -5,
  marginBottom: 15,
  fontSize: 12,
}));

export const TasksFeature = React.memo(function TasksFeature(props: {
  params: TasksEDataParamsType;
}): JSX.Element {
  const { open, CXMenuContainer, ref } = useWrappedPopOver<HTMLButtonElement>();
  const navigate = useNavigate();
  const navigateToICView = useNavigateTo({
    feature: Features.tasks,
    params: null,
  });
  const createNewProject = useNavigateTo({
    feature: Features.tasks,
    params: { view: "create-new-project" },
  });
  const { projects: _projects, projectsQuery } = useMyProjects();
  const { pinnedProjects } = usePinnedProjects();
  const projects: TaskProject[] = React.useMemo(
    () =>
      _projects.data
        .filter((p) => p.isPersonalProjectFor == null)
        .sort((a, b) => {
          if (
            pinnedProjects.includes(a.uuid) &&
            !pinnedProjects.includes(b.uuid)
          ) {
            return -1;
          } else if (
            pinnedProjects.includes(b.uuid) &&
            !pinnedProjects.includes(a.uuid)
          ) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        }),
    [_projects.data, pinnedProjects],
  );
  const teamsQuery = useListMyTeams(null);
  const sprintsQuery = useGetMySprints(null);
  const personalProjectQuery = useGetPersonalProject(null);
  const personalProject = personalProjectQuery.data?.project ?? null;
  const navigateToPersonalProject = useNavigateTo(
    personalProject == null
      ? {
        feature: Features.tasks,
        params: {
          view: "personal-project-splash",
        },
      }
      : {
        feature: Features.tasks,
        params: {
          view: "project",
          projectUUID: personalProject.uuid,
        },
      },
  );
  const isSolo = useIsSolo();


  return (
    <TabDetails icon={faSquareKanban} label="Tasks">
      <CXMenuContainer>
        <TaskPlusMenu />
      </CXMenuContainer>
      <LeftSidebar>
        <Sidebar.Header
          label="Tasks"
          actionButton={
            <CircleButton onClick={open} _ref={ref} icon={faPlus} size={30} />
          }
        />
        <LSButton
          icon={{ fa: faListCheck }}
          label={isSolo ? "All Your Tasks" : "Your Tasks"}
          isActive={props.params === null || props.params.view === "ic-view"}
          onClick={navigateToICView}
        />
        {!isSolo ? (
          <LSButton
            icon={{ fa: faSquareKanban }}
            label="Your Personal Board"
            isActive={
              props.params?.view === "project" &&
              props.params.projectUUID === personalProject?.uuid
            }
            onClick={navigateToPersonalProject}
          />
        ) : null}

        <LSText
          icon={null}
          label="Sprints"
          right={<NewSprintButton />}
        />
        {sprintsQuery.data != null ?
          sprintsQuery.data.sprints.length > 0 ?
            sprintsQuery.data.sprints.map((sprint) => (
              <>
                <LSButton
                  key={sprint.uuid}
                  isActive={
                    props.params != null &&
                    "sprintUUID" in props.params &&
                    props.params.sprintUUID === sprint.uuid
                  }
                  label={sprint.name}
                  icon={{
                    fa:
                      sprint.status === SprintStatusEnum.active
                        ? faRunning
                        : faRoute,
                  }}
                  onClick={() => {
                    navigate({
                      feature: Features.tasks,
                      params:
                        sprint.status === SprintStatusEnum.active
                          ? {
                            view: "sprint",
                            sprintUUID: sprint.uuid,
                          }
                          : {
                            view: "sprint-planning",
                            teamUUID: sprint.teamUUID,
                            sprintUUID: sprint.uuid,
                          },
                    });
                  }}
                />
                <AnimateChangeInHeight>
                  {(props.params != null &&
                    "sprintUUID" in props.params &&
                    props.params.sprintUUID === sprint.uuid) && sprint.status === SprintStatusEnum.active ? (
                    <>
                      <LSButton
                        label="Sprint Planning"
                        icon={{ fa: faRoute }}
                        onClick={() => {
                          navigate({
                            feature: Features.tasks,
                            params: {
                              view: "sprint-planning",
                              teamUUID: sprint.teamUUID,
                              sprintUUID: sprint.uuid,
                            },
                          });
                        }}
                        isActive={props.params?.view === "sprint-planning"}
                        indent
                      />
                    </>
                  ) : <div />}
                </AnimateChangeInHeight>
              </>
            ))
            : <div style={{ textAlign: "center", opacity: 0.25, fontFamily: "hiyllo", fontSize: 12.5 }}><FontAwesomeIcon icon={faEmptySet} /> No Sprints</div>
          : null}

        <LSText
          icon={null}
          label="Projects"
          right={
            <LSTinyButton onClick={createNewProject}>
              + New Project
            </LSTinyButton>
          }
        />
        {projects.length !== 0 ? (
          projects.map((project) => (
            <TaskProjectLSButton
              key={project.uuid}
              project={project}
              isActive={
                props.params?.view === "project" ||
                  props.params?.view === "project-planning" ||
                  props.params?.view === "project-settings" ||
                  props.params?.view === "task"
                  ? props.params.projectUUID === project.uuid
                  : false
              }
              projectsQuery={projectsQuery}
            />
          ))
        ) : !projectsQuery.isLoading ? (
          <EmptyStateText>No Projects</EmptyStateText>
        ) : null}
        {!isSolo ? (
          <>
            <LSText icon={null} label="Teams" />
            {teamsQuery.data?.teams.map((team) => (
              <LSButton
                icon={{ fa: faBriefcase }}
                label={team.name}
                isActive={
                  props.params?.view === "sprint-planning" &&
                  props.params.teamUUID === team.uuid &&
                  props.params.sprintUUID == null
                }
                onClick={() =>
                  navigate({
                    feature: Features.tasks,
                    params: {
                      view: "sprint-planning",
                      teamUUID: team.uuid,
                    },
                  })
                }
                key={team.uuid}
              />
            ))}
            {!teamsQuery.isLoading &&
              !teamsQuery.error &&
              teamsQuery.data?.teams.length === 0 ? (
              <EmptyStateText>No Teams</EmptyStateText>
            ) : null}
          </>
        ) : null}
      </LeftSidebar>
      <SprintsContext.Provider value={sprintsQuery.data?.sprints ?? []}>
        <MainContent padding={15}>
          {props.params?.view === "personal-project-splash" ? (
            <PersonalProjectSplash />
          ) : props.params?.view === "create-sprint" ? (
            <CreateSprintWrapper
              teamUUID={props.params.teamUUID}
              rolloverFromSprint={props.params.rolloverFromSprint ?? null}
            />
          ) : props.params?.view === "create-new-project" ? (
            <CreateNewProjectView projectsQuery={projectsQuery} />
          ) : props.params?.view === "project" ? (
            <TaskProjectView
              projectUUID={props.params.projectUUID}
              key={props.params.projectUUID}
            />
          ) : props.params?.view === "sprint" ? (
            <SprintKanbanView
              sprintUUID={props.params.sprintUUID}
              key={props.params.sprintUUID}
            />
          ) : props.params?.view === "project-planning" ? (
            <ProjectPlanningViewWrapper
              projectUUID={props.params.projectUUID}
              key={props.params.projectUUID}
            />
          ) : props.params?.view === "sprint-planning" ? (
            <SprintPlanningView
              teamUUID={props.params.teamUUID}
              sprintUUID={props.params.sprintUUID ?? null}
              key={props.params.teamUUID}
            />
          ) : props.params?.view === "project-settings" ? (
            <ProjectSettingsView
              projectUUID={props.params.projectUUID}
              projectsQuery={projectsQuery}
            />
          ) : props.params?.view === "create-task" ? (
            <CreateNewTaskView
              projectUUID={props.params.projectUUID ?? null}
              sprintUUID={props.params.sprintUUID ?? null}
            />
          ) : props.params?.view === "task" ? (
            <TaskView uuid={props.params.taskUUID} withNavigationButtons />
          ) : props.params?.view === "ic-view" ? (
            <TasksICView />
          ) : props.params?.view === "user-tasks" ? (
            <UserTasksView userId={props.params.userId} />
          ) : (
            <TasksICView />
          )}
        </MainContent>
      </SprintsContext.Provider>
    </TabDetails>
  );
});

export default TasksFeature;
