/* !STOP STOP STOP!
!STOP STOP STOP!
!STOP STOP STOP! This is a GENERATED file, if you want to make changes
you should make them inside the common/ folder NOT here */
import { BuiltInFolderEnum } from "../organization/builtin-folders";
import { MessageBase } from "./base";

export enum MailMessageDirection {
  IN = "IN",
  OUT = "OUT",
}

export enum MailMessageStatusEnum {
  // OUT
  draft = "draft",
  outbox = "outbox",
  sent = "sent",
  delivered = "delivered",
  bounced = "bounced",
  // IN
  received = "received",
  deleted = "deleted",
}

export interface MailMessageAttachment {
  fsId: string;
  filename: string;
  size: number;
}

export interface MailMessageAttachmentEnhanced extends MailMessageAttachment {
  src: string;
}

export interface MailMessage {
  uuid: string;
  base: MessageBase;
  storageUsed?: number;
  mailboxUUID: string;
  direction: MailMessageDirection;
  /** If IN, date received; if OUT, date sent */
  date: Date;
  /**
   * Date this record was last updated, for cacheing purposes
   */
  lastUpdated: Date;
  read: boolean;
  builtInFolders: BuiltInFolderEnum[];
  /**
   * If `true`, the message is pending to be sent. Do not send when
   * this is true.
   */
  sendPending?: boolean;
  /**
   * If `true`, sending of the message is cancelled
   */
  sendCancelled?: boolean;
  /**
   * If `true`, message has already been dispatched
   */
  dispatched?: boolean;
  dispatchDate?: Date;
  status: MailMessageStatusEnum;
  threadUUID: string;
  isVerified?: boolean;
  attachments?: MailMessageAttachment[];
  flaggedAsSpam?: boolean;
  debugData?: string;
  /**
   * HTML safe to be rendered in a webview
   */
  renderableHTML?: string | null;
  renderVersion?: number;
  /**
   * Actual user who sent the message, if available
   */
  trueSender: string | null;

  /**
   * 1 = Definitely Not Spam
   * 0 = Definitely Spam
   */
  spamScore?: number;

  /**
   * Summary generated by Nymbl
   */
  nymblSummary?: string | null;

  /**
   * Nymbl has detected that this is a marketing email
   */
  nymblIsMarketing?: boolean;
}

export interface MailMessageEnhanced extends MailMessage {
  enhancedAttachments: MailMessageAttachmentEnhanced[];
}
